import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';
import GalleryPage from './GalleryPage';
import ProductPage from './ProductPage';
import Cart from './Cart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import OrderDetailsPage from './OrderDetailsPage';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
const stripePromise = loadStripe('pk_test_51MCPB7ITr0D8qqxQpkcXSDE0EJ3de7OG8glWVa5BtVTSXHUx0Gqe0HL5y1OYm4Cf6BMMdgywTKugoNrdZVw6MAr4000BZwCzIL');

const App = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    // Charger le contenu du panier depuis le stockage local lors du montage du composant
    const savedCartItems = localStorage.getItem('cartItems');
    if (savedCartItems) {
      setCartItems(JSON.parse(savedCartItems));
    }
  }, []);

  useEffect(() => {
    // Enregistrer le contenu du panier dans le stockage local à chaque changement du panier
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const handleViewProduct = (product) => {
    setSelectedProduct(product);
  };

  const handleBackToGallery = () => {
    setSelectedProduct(null);
  };

  const handleAddToCart = (product, productType, dimension, frame, downloadFormat, price) => {
    const productInfo = {
      id: product.asset_id,
      title: product.title,
      type: productType,
      dimension: dimension,
      frame: frame,
      price: price,
      downloadFormat: downloadFormat,
      urls: product.secure_url, // Ajout des URLs de l'image
      alt_description: product.filename, // Ajout de la description alternative de l'image
    };

    setCartItems([...cartItems, productInfo]);
  };

  const handleRemoveFromCart = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <div className="App">
      <Router>
        <header>
          <a href='https://pubtv-immo.com/' className="logo">
            <img src="https://www.imagyn.be/wp-content/uploads/2021/05/logo-imagyn-studio-1.png" width="50%" alt="Logo" />
          </a>
          <div className="cart-icon" onClick={toggleCart}>
            <FontAwesomeIcon icon={faShoppingCart} size="40px" color='white' />
          </div>
          <nav className="nav">
            <ul>
              <li>
                <Link to="/">Acceuil</Link>
              </li>
              <li>
                <Link to="/order">Commande</Link>
              </li>
            </ul>
          </nav>
        </header>
        <main>
          {isCartOpen && (
            <Cart
              cartItems={cartItems}
              setCartItems={setCartItems}
              handleRemoveFromCart={handleRemoveFromCart}
            />
          )}
        </main>
        <Routes>
          <Route path="/" element={<GalleryPage handleViewProduct={handleViewProduct} />} />
          <Route
            path="/product/:productId"
            element={<ProductPage handleAddToCart={handleAddToCart} handleBackToGallery={handleBackToGallery} />}
          />
          <Route path="/cart" element={<Cart cartItems={cartItems} handleRemoveFromCart={handleRemoveFromCart} />} />
          <Route path="/order" element={<OrderDetailsPage cartItems={cartItems} stripePromise={stripePromise} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
