import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './ProductPage.css';

const ProductPage = ({ handleAddToCart, handleBackToGallery }) => {
  const { productId } = useParams();
  console.log(productId);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://backend-imagyn.onrender.com/api/cloudinary/images/${encodeURIComponent(productId)}`);
        setProduct(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProduct();
  }, [productId]);

  const [productType, setProductType] = useState('physical');
  const [dimension, setDimension] = useState('20x30');
  const [frame, setFrame] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState('original');
  const [price, setPrice] = useState(0);

  const physicalProductSpecs = [
    { dimension: '20x30', frame: false, price: 25 },
    { dimension: '20x30', frame: true, price: 30 },
    { dimension: '30x30', frame: false, price: 25 },
    { dimension: '30x30', frame: true, price: 30 },
    { dimension: '30x40', frame: false, price: 25 },
    { dimension: '30x40', frame: true, price: 35 },
    { dimension: '40x60', frame: false, price: 25 },
    { dimension: '40x60', frame: true, price: 35 },
    { dimension: '50x70', frame: false, price: 25 },
    { dimension: '50x70', frame: true, price: 35 },
    { dimension: '60x90', frame: false, price: 25 },
    { dimension: '60x90', frame: true, price: 35 },
  ];

  const downloadProductSpecs = [
    { format: 'original', price: 10 },
    { format: 'large', price: 8 },
    { format: 'medium', price: 6 },
    { format: 'small', price: 5 },
  ];

  useEffect(() => {
    calculatePrice();
  }, [productType, dimension, frame, downloadFormat]);

  const calculatePrice = () => {
    if (productType === 'physical') {
      const spec = physicalProductSpecs.find((spec) => spec.dimension === dimension && spec.frame === frame);
      if (spec) {
        setPrice(spec.price);
      }
    } else if (productType === 'download') {
      const spec = downloadProductSpecs.find((spec) => spec.format === downloadFormat);
      if (spec) {
        setPrice(spec.price);
      }
    }
  };

  const handleTypeChange = (type) => {
    setProductType(type);
  };

  const handleDimensionChange = (selectedDimension) => {
    setDimension(selectedDimension);
  };

  const handleFrameChange = (isChecked) => {
    setFrame(isChecked);
  };

  const handleFormatChange = (selectedFormat) => {
    setDownloadFormat(selectedFormat);
  };


  return (
    <div className='page'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="product-page">
            <div className="image-container">
            <img src={product.secure_url} alt={product.filename} onContextMenu={(e) => e.preventDefault()} />
            </div>
            <div className="product-info">
              <p>{product.filename}</p>
              <div className="product-type">
                <div class="radio-inputs">
                  <label class="radio">
                    <input
                      type="radio"
                      name="productType"
                      value="physical"
                      checked={productType === 'physical'}
                      onChange={() => handleTypeChange('physical')}
                    />
                    <span class="name">Produit physique</span>
                  </label>
                  <label class="radio">
                    <input
                      type="radio"
                      name="productType"
                      value="download"
                      checked={productType === 'download'}
                      onChange={() => handleTypeChange('download')}
                    />
                    <span class="name">Téléchargement</span>
                  </label>
                </div>
              </div>
              {productType === 'physical' && (
                <div className="dimension-options">
                  <h3>Options de dimension</h3>
                  <select value={dimension} onChange={(e) => handleDimensionChange(e.target.value)}>
                    <option value="20x30">20x30 cm</option>
                    <option value="30x30">30x30 cm</option>
                    <option value="30x40">30x40 cm</option>
                    <option value="40x60">40x60 cm</option>
                    <option value="50x70">50x70 cm</option>
                    <option value="60x90">60x90 cm</option>
                  </select>
                  <label>
                    <input
                      type="checkbox"
                      checked={frame}
                      onChange={(e) => handleFrameChange(e.target.checked)}
                    />
                    Avec cadre
                  </label>
                </div>
              )}
              {productType === 'download' && (
                <div className="format-options">
                  <h3>Options de format de téléchargement</h3>
                  <select value={downloadFormat} onChange={(e) => handleFormatChange(e.target.value)}>
                    <option value="original">Original (qualité native)</option>
                    <option value="large">Grand : 1920x2880</option>
                    <option value="medium">Moyen : 1280x1920</option>
                    <option value="small">Petit : 640x960</option>
                  </select>
                </div>
              )}
              <div className="price">
                <p>Prix HTVA: {price} €</p>
                <p>Prix TVA : {(price * 1.21).toFixed(2)} €</p>

              </div>
              <div className="buttons">
                <button onClick={() => handleAddToCart(product, productType, dimension, frame, downloadFormat, price)}>Ajouter au panier</button>
                <button onClick={handleBackToGallery}>Retour à la galerie</button>
              </div>
            </div>
          </div>
          <div className='product-footer'>
            <p>
              Merci de votre intérêt pour nos photos ! Nous sommes ravis de pouvoir partager notre passion avec vous. <span style={{ color: '#9DBB1B' }}>Si vous avez un projet ou une idée de réalisation qui nécessite l'utilisation de nos photos, n'hésitez pas à nous contacter.</span> <br /> <br />
              Vous pouvez nous joindre au numéro de téléphone suivant :<span style={{ color: '#9DBB1B' }}>0474 93 69 74 </span> ou nous envoyer un e-mail à <span style={{ color: '#9DBB1B' }}> david@ynova.be</span>.
              <br /><br /><br />

              Nous tenons à souligner que toutes nos photos sont soumises aux droits d'auteur.<span style={{ color: '#9DBB1B' }}> Il est strictement interdit de les reproduire ou de les utiliser sans notre permission préalable.</span> Nous accordons une grande importance à la protection de notre travail créatif et nous apprécions votre respect à cet égard.
              <br /><br />

              Nous sommes impatients de discuter de vos projets et de voir comment nos photos peuvent contribuer à leur réalisation. Contactez-nous dès aujourd'hui pour en savoir plus !
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductPage;