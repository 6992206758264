import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './OrderDetailsPage.css'

const Order = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [orders, setOrders] = useState([]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Effectuer la requête pour récupérer les commandes
    fetch(`https://backend-imagyn.onrender.com/commandes/${email}`)
      .then(response => response.json())
      .then(data => setOrders(data))
      .catch(error => console.error('Erreur lors de la récupération des commandes:', error));

    setEmail(''); // Réinitialiser l'adresse e-mail
  };

  useEffect(() => {
    const fetchOrderData = async () => {
      const sessionID = new URLSearchParams(location.search).get('session_id');
      if (!sessionID) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://backend-imagyn.onrender.com/order?session_id=${sessionID}`);
        setIsSuccess(true);
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };

    fetchOrderData();
  }, [location.search]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="form-container">
      {isSuccess ? (
        <p>Merci votre commande a été enregistrée avec succès, Vous recevrez un email avec vos photos, ou les informations de livraison</p>
      ) : (
        <></>
      )}
      <div className="form-wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <label className="form-label">
            Adresse e-mail  
            <input className="form-input" type="email" value={email} onChange={handleEmailChange} required />
          </label>
          <button className="form-button" type="submit">Rechercher</button>
        </form>

        <h2>Commandes</h2>
        <div className='order-border'>
        {orders.length > 0 ? (
          <ul className="order-list">
            {orders.map(order => (
              <li key={order.id} className="order-item">
                <h3>Commande #{order.id}</h3>
                <p>Email : {order.email}</p>
                <p>Créé le : {order.created_at}</p>
                <p>Statut : En cours de traitement</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>Aucune commande trouvée.</p>
        )}
        </div>
      </div>
    </div>
  );

};

export default Order;