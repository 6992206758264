import React from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import "./Cart.css"

const stripePromise = loadStripe('pk_test_51MCPB7ITr0D8qqxQpkcXSDE0EJ3de7OG8glWVa5BtVTSXHUx0Gqe0HL5y1OYm4Cf6BMMdgywTKugoNrdZVw6MAr4000BZwCzIL');

const Cart = ({ cartItems, setCartItems, handleRemoveFromCart }) => {

  const calculatePriceHTVA = (price) => {
    return price;
  };

  const calculatePriceTTC = (price) => {
    return price * 1.21;
  };

  const handleCheckout = async () => {
    try {
      console.log(cartItems);
      const response = await axios.post('https://backend-imagyn.onrender.com/create-checkout-session', { products: cartItems });
      const stripe = await stripePromise;
      const { error, sessionId } = response.data;
      const sessionID = response.data.sessionId;
      const result = await stripe.redirectToCheckout({ sessionId: sessionID });

      if (error) {
        console.error(error);
        // Gérer les erreurs de redirection de paiement
      } else {
        const result = await stripe.redirectToCheckout({ sessionId });

        if (result.error) {
          console.error(result.error);
          // Gérer les erreurs de redirection de paiement
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("cartItems", cartItems);
  return (
    <div className="cart-container">
      {cartItems.length === 0 ? (
        <p className="cart-empty-message">Le panier est vide.</p>
      ) : (
        <div>
          <ul className="cart-items-list">
            {cartItems.map((item, index) => (
              <li className="cart-item" key={index}>
                <img src={item.urls} alt={item.alt_description} onContextMenu={(e) => e.preventDefault()} />
                <div className="product-info">
                  <div>
                    {item.alt_description}
                  </div>
                  <div className='price-list'>
                    <div>
                      <span className="price-htva">{calculatePriceHTVA(item.price).toFixed(2)}</span> HTVA
                    </div>
                    <div>
                      <span className="price-ttc">{calculatePriceTTC(item.price).toFixed(2)}</span> TTC (21%)
                    </div>
                  </div>
                </div>
                <button
                  className="cart-item-remove-button"
                  onClick={() => handleRemoveFromCart(index)}
                >
                  Supprimer
                </button>
              </li>
            ))}
          </ul>
          <button className="cart-checkout-button" onClick={handleCheckout}>
            Passer la commande
          </button>
        </div>
      )}
    </div>
  );
};

export default Cart;