import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { RotateLoader } from 'react-spinners';
import { useSpring, animated } from 'react-spring';
import './App.css';

const GalleryPage = ({ handleViewProduct, handleAddToCart }) => {
  const [images, setImages] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [subFolders, setSubFolders] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('https://backend-imagyn.onrender.com/api/cloudinary/images');
  
        const mainFoldersSet = new Set();
  
        response.data.forEach((image) => {
          const folderPath = image.folder.split('/');
          const mainFolder = folderPath[0].trim();
          mainFoldersSet.add(mainFolder);
        });
  
        setFolders([...mainFoldersSet]);
        setImages(response.data);
        setHasMore(response.data.length > 0);
      } catch (error) {
        console.log(error);
      }
  
      setIsLoading(false);
    };
  
    fetchImages();
  }, []);

  const filterImagesByFolder = (folder) => {
    setSelectedFolder(folder);

    if (folder === '') {
      setImages([]);
      setHasMore(true);
    } else {
      const filteredImages = images.filter((image) => {
        const folderPath = image.folder.split('/');
        const mainFolder = folderPath[0].trim();
        return mainFolder === folder || image.folder === folder;
      });
      setImages(filteredImages);
      setHasMore(filteredImages.length > 0);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const loaderStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  `;

  const textAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(-10px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 300,
    config: { duration: 500 },
  });


  return (
    <div>
      <div className={`folder-menu ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <button onClick={() => filterImagesByFolder('')}>Tous</button>
        {selectedFolder === '' ? (
          folders.map((folder) => (
            <button key={folder} onClick={() => filterImagesByFolder(folder)}>
              {folder}
            </button>
          ))
        ) : (
          <>
            <button onClick={() => setSelectedFolder('')}>{selectedFolder}</button>
            <div className="sub-folder-menu">
              {subFolders.map((subFolder) => (
                <button
                  key={subFolder}
                  onClick={() => filterImagesByFolder(`${selectedFolder}/${subFolder}`)}
                >
                  {subFolder}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
      {isLoading ? (
        <div className="loader" css={loaderStyles}>
          <animated.div>
            <img
              src="https://www.imagyn.be/wp-content/uploads/2014/05/imagyn-logo.png"
              alt="Logo"
            />
          </animated.div>
          <br />        <br />        <br />
          <RotateLoader color="#9DBB1B" />
        </div>
      ) : (
        <div className="gallery">
          {images
            .filter((image) => selectedFolder === '' || image.folder === selectedFolder)
            .map((image) => (
              <div key={image.public_id} className="image">
                {image.imageUrl && <img src={image.imageUrl} alt={image.filename} />}
                <div className="overlay">
                  <Link to={`/product/${image.asset_id}`}>Voir le produit !</Link>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );

};

export default GalleryPage;
